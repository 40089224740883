.animation.bounce {
	-webkit-animation: bounce 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
		alternate both;
	animation: bounce 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
		alternate both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-19 18:55:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes bounce {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}
}
@keyframes bounce {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}
}
