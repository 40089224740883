.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.button {
	background: #000;
	width: 100px;
	height: 25px;
	border-radius: 100px;
	line-height: 25px;
	margin: 0 auto;
}
.button#contact {
	background: #000000b8;
}
.app-container {
	display: inline-block;
	position: relative;
}
#site {
	position: relative;
	width: 100%;
	height: 100vh;
}
#site-gradient {
	height: 100px;
	margin-top: -100px;
	background: rgb(0, 0, 0);
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 1) 0%,
		rgba(0, 0, 0, 0) 100%
	);
}
#site-container {
	width: 85%;
	position: relative;
	margin: 0 auto;
	padding-top: 20px;
}
#welcome {
	font-family: "JetBrainsMono";
	text-align: center;
}
