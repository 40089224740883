#portfolio {
	margin: 30px 0;
	text-align: center;
}
#portfolio h3 {
	margin-top: 60px;
	text-align: center;
	font-size: 30px;
}
.project {
	display: inline-grid;
	width: 300px;
	margin: 0 10px;
	text-align: left;
}
.project:hover img {
	transform: scale(1.1);
}
img.project-preview {
	width: 300px;
	height: 200px;
	max-width: 100%;
	transition: 0.5s;
}

@media screen and (max-width: 425px) {
	.project {
		margin: 0;
		max-width: 100%;
	}
}
