@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Ubuntu&display=swap);
body {
  margin: 0;
  background-color: #000;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
  color: #fff;
  vertical-align: middle;
  font-family: 'JetBrainsMono';
}
a {
  cursor: pointer;
}
a, p {
  font-family: 'Roboto', sans-serif;
  font-family: 'JetBrainsMono';
  color: #fff;
  text-decoration: none;
}
@font-face {
	font-family: "JetBrainsMono";
	src: local("JetBrainsMono"),
		url(/static/media/JetBrainsMono-Regular.ebe2f0f7.woff) format("woff");
}

.animation.bounce {
	-webkit-animation: bounce 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
		alternate both;
	animation: bounce 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
		alternate both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-19 18:55:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes bounce {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}
}
@keyframes bounce {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}
}

#profile-header {
	position: relative;
	margin: 0 auto;
	max-width: 100%;
	width: 100%;
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}
#profile-img {
	max-width: 100%;
}
#profile-img-mobile {
	display: none;
	max-width: 90%;
}
#name-container {
	width: 550px;
}
img.img-icon {
	width: 40px;
	-webkit-filter: invert(1);
	        filter: invert(1);
	margin: 10px;
}

#profile-contact {
	width: 240px;
	margin: 0 auto;
}

@media screen and (min-width: 1024px) {
	#profile-header {
		flex-direction: row;
	}
	#name-container {
		margin-top: 25px;
		margin-left: -75px;
	}
}

#intro {
	height: 100vh;
	width: 100%;
}
#intro-container {
	width: 100%;
	height: 100%;
}
#intro-content {
	position: absolute;
	text-align: center;
	width: 100%;
	margin: auto;
	padding-top: 30vh;
}
#intro-logo {
	width: 600px;
	max-width: 100%;
}

#portfolio {
	margin: 30px 0;
	text-align: center;
}
#portfolio h3 {
	margin-top: 60px;
	text-align: center;
	font-size: 30px;
}
.project {
	display: inline-grid;
	width: 300px;
	margin: 0 10px;
	text-align: left;
}
.project:hover img {
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
}
img.project-preview {
	width: 300px;
	height: 200px;
	max-width: 100%;
	transition: 0.5s;
}

@media screen and (max-width: 425px) {
	.project {
		margin: 0;
		max-width: 100%;
	}
}

#menu-header {
	width: 100%;
	padding-top: 50px;
	position: relative;
	margin: 0 auto;
	text-align: center;
	transition: visibility 0s, opacity 0.5s linear;
	transition-delay: 0.5s;
	opacity: 0;
	display: none;
}

#menu-header .menu-item {
	color: #fff;
	width: 100px;
	display: inline-block;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		-webkit-animation: App-logo-spin infinite 20s linear;
		        animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.button {
	background: #000;
	width: 100px;
	height: 25px;
	border-radius: 100px;
	line-height: 25px;
	margin: 0 auto;
}
.button#contact {
	background: #000000b8;
}
.app-container {
	display: inline-block;
	position: relative;
}
#site {
	position: relative;
	width: 100%;
	height: 100vh;
}
#site-gradient {
	height: 100px;
	margin-top: -100px;
	background: rgb(0, 0, 0);
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 1) 0%,
		rgba(0, 0, 0, 0) 100%
	);
}
#site-container {
	width: 85%;
	position: relative;
	margin: 0 auto;
	padding-top: 20px;
}
#welcome {
	font-family: "JetBrainsMono";
	text-align: center;
}

